import { useEffect, useState } from 'react'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress,
  Checkbox,
  Popper
} from '@mui/material'
import { useStyles } from './styles'
import { Button } from '../../components'
import { searchProposals } from '../../store/api'
import Autocomplete from '@mui/material/Autocomplete'
import SearchIcon from '@mui/icons-material/Search'
import SearchNew from '../../sections/ListFilter/SearchNew'
import Button2 from '../Button/Button2'

const DocumentAdd = (props) => {
  const {
    isOpen = false,
    handleClose = () => {},
    actionButtons = [],
    headerText = 'Attach Files',
    prevSelectedCollections = null,
    parent = null
  } = props

  const classes = useStyles()

  const [selectedDocuments, setSelectedDocuments] = useState([])

  const [options, setOptions] = useState([])
  const [inputValue, setInputValue] = useState('')
  const [loading, setLoading] = useState(false)

  const fetchOptions = async (query) => {
    setLoading(true)
    console.log('calling fetchOptions', query)
    try {
      const res = await searchProposals(query)
      if (res.status === 200) {
        const { data } = res || {}
        const { results } = data
        const uniqueResults = results.filter(
          (item, index, self) =>
            index === self.findIndex((t) => t.value === item.value)
        )
        setOptions(uniqueResults)
      }
    } catch (error) {
      console.error('Error fetching data:', error)
      setOptions([])
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (inputValue === '') {
        fetchOptions(null)
      } else {
        fetchOptions(inputValue)
      }
    }, 500)
    return () => clearTimeout(delayDebounceFn)
  }, [inputValue])

  useEffect(() => {
    if (prevSelectedCollections) {
      setSelectedDocuments(prevSelectedCollections)
    }
  }, [prevSelectedCollections, isOpen])

  const handleSelect = (item) => {
    if (!item) setSelectedDocuments([])
    else setSelectedDocuments([item])
  }

  const closeModel = () => {
    handleClose()
    setTimeout(() => {
      setSelectedDocuments([])
    }, 100)
  }

  return (
    <Dialog
      data-dialog-parent={parent}
      maxWidth="xs"
      open={isOpen}
      onClose={closeModel}
      sx={{
        zIndex: 9999,
        '& .MuiPaper-root': {
          borderRadius: '15px'
        }
      }}
    >
      <div className="p-3 px-4 mt-1">
        <p className="m-0 text-sm font-medium">{headerText}</p>
        <p className="m-0 text-xs text-grey-500 mb-2">
          Choose which files you want to chat about. Use fewer references for
          more accurate responses.
        </p>
        <SearchNew
          value={inputValue}
          onChange={(value) => {
            setInputValue(value)
          }}
          placeholder="Search files"
          loading={loading}
        />
        {/* <DialogTitle>{headerText}</DialogTitle> */}
        <div
          className=""
          style={{
            minHeight: '350px',
            overflow: 'auto'
          }}
        >
          <div className="flex flex-col mt-2 gap-1">
            {options.map((file, i) => {
              return (
                <div
                  key={i}
                  className="text-sm py-2 flex items-center gap-2 hover-bg-grey-100 px-2 rounded-md cursor-pointer overflow-hidden"
                  onClick={() => {
                    handleSelect(file)
                  }}
                >
                  <div
                    className="size-3 rounded-sm"
                    style={{
                      minWidth: '0.75rem',
                      background:
                        selectedDocuments[0]?.value === file?.value
                          ? 'var(--grey-700)'
                          : 'var(--grey-200)'
                    }}
                  ></div>{' '}
                  <p className="m-0 whitespace-no-wrap text-xs font">
                    {file.label}
                  </p>
                </div>
              )
            })}
          </div>
        </div>
        {/* <DialogContent>
          <Autocomplete
            sx={{
              zIndex: 1302
            }}
            PopperComponent={(props) => (
              <Popper {...props} style={{ zIndex: 1303, ...props?.style }} />
            )}
            freeSolo
            options={options}
            onInputChange={(event, newValue) => {
              if (event.type === 'change') setInputValue(newValue)
              else if (event.type === 'click') {
                const option = options.filter(
                  (option) => option.label === newValue
                )
                handleSelect(option[0])
              }
            }}
            loading={loading}
            renderOption={(props, option) => {
              return (
                <Box
                  key={option.id}
                  {...props}
                  component={'li'}
                  data-dialog-parent={parent}
                  sx={{
                    zIndex: 1302
                  }}
                >
                  <Box>{option?.label}</Box>
                </Box>
              )
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                style={{
                  width: '100%',
                  fontSize: '12px',
                  outline: 'none',
                  padding: '5px'
                }}
                sx={{
                  zIndex: 1302,
                  '& .MuiOutlinedInput-root': {
                    padding: '5px',
                    '& fieldset': {
                      border: 'var(--grey-300) 1px solid'
                    },
                    '&:hover fieldset': {
                      border: 'var(--grey-500) 1px solid'
                    },
                    '&.Mui-focused fieldset': {
                      border: 'var(--grey-500) 1px solid'
                    }
                  }
                }}
                placeholder={'Search for documents'}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      <SearchIcon sx={{ fontSize: '18px' }} />
                      {params.InputProps.startAdornment}
                    </>
                  ),
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size={18} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
          />
        </DialogContent> */}

        <div className="flex justify-end gap-2">
          <Button2 secondary noOutline onClick={closeModel}>
            Cancel
          </Button2>
          {actionButtons.map((button, index) => {
            const { label = '', action = () => {}, condition = true } = button
            return (
              condition && (
                <Button2
                  primary
                  key={index}
                  disabled={!(selectedDocuments.length > 0)}
                  onClick={() => {
                    action([...selectedDocuments])
                    closeModel()
                  }}
                >
                  {label}
                </Button2>
              )
            )
          })}
        </div>
        {/* <DialogActions>
          <Button variant="outlined" onClick={closeModel}>
            Cancel
          </Button>
          {actionButtons.map((button, index) => {
            const { label = '', action = () => {}, condition = true } = button
            return (
              condition && (
                <Button
                  key={index}
                  disabled={!(selectedDocuments.length > 0)}
                  onClick={() => {
                    action([...selectedDocuments])
                    closeModel()
                  }}
                >
                  {label}
                </Button>
              )
            )
          })}
        </DialogActions> */}
      </div>
    </Dialog>
  )
}

export default DocumentAdd
