import { cloneDeep } from 'lodash'
import {
  ADD_DOCUMENT_TO_CHAT_FILTER,
  ADD_CONTENT_TO_CHAT,
  SET_POSITION_CHAT,
  CLEAR_POSITION_CHAT,
  SET_COND_POSITION_CHAT,
  TOGGLE_POSITION_CHAT,
  SET_CHAT_STATE,
  CLEAR_DOCUMENT_TO_CHAT_FILTER,
  ATTACH_ALERT_TO_CHAT,
  SET_TEXT_CHAT,
  ADD_TRACKER_ID_TO_CHAT
} from '../../../actionTypes'

const addDocumentToChat =
  (data, position = { x: 0, y: 0 }) =>
  async (dispatch) => {
    dispatch({
      type: ADD_DOCUMENT_TO_CHAT_FILTER,
      payload: data
    })
    dispatch({
      type: SET_COND_POSITION_CHAT,
      payload: position
    })
  }

const addTrackerIdToChat = (data) => async (dispatch) => {
  dispatch({
    type: ADD_TRACKER_ID_TO_CHAT,
    payload: data
  })
}

const attachAlertToChat = (data) => async (dispatch) => {
  console.log('attachAlertToChat', data)
  dispatch({
    type: ATTACH_ALERT_TO_CHAT,
    payload: data
  })
}

const clearDocumentFromChat = (data) => async (dispatch) => {
  dispatch({
    type: CLEAR_DOCUMENT_TO_CHAT_FILTER,
    payload: {}
  })
}

const addContentToChat =
  (data, position = { x: 0, y: 0 }) =>
  async (dispatch) => {
    dispatch({
      type: ADD_CONTENT_TO_CHAT,
      payload: data
    })
    dispatch({
      type: SET_COND_POSITION_CHAT,
      payload: position
    })
  }

const setPositionChat = (data) => async (dispatch) => {
  dispatch({
    type: SET_POSITION_CHAT,
    payload: data
  })
}

const setTextChat = (data) => async (dispatch) => {
  dispatch({
    type: SET_TEXT_CHAT,
    payload: data
  })
}

const clearPositionChat = () => async (dispatch) => {
  dispatch({
    type: CLEAR_POSITION_CHAT
  })
}

const toggleChatPosition = (data) => async (dispatch) => {
  dispatch({
    type: TOGGLE_POSITION_CHAT,
    payload: data
  })
}

const toggleChatState = (data) => async (dispatch) => {
  dispatch({
    type: SET_CHAT_STATE,
    payload: data
  })
}

export {
  addDocumentToChat,
  addContentToChat,
  clearDocumentFromChat,
  setPositionChat,
  clearPositionChat,
  toggleChatPosition,
  toggleChatState,
  attachAlertToChat,
  setTextChat,
  addTrackerIdToChat
}
