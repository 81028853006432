import { Box, Tooltip } from '@mui/material'
import { useState, useEffect, useRef } from 'react'

const EllipsisTooltip = (props) => {
  const {
    text,
    tooltipText = '',
    tooltipProps = {
      placement: 'top',
      enterDelay: 1000,
      enterNextDelay: 1000,
      componentsProps: {
        tooltip: {
          sx: {
            '& a': {
              color: 'white !important'
            }
          }
        }
      }
    },
    ...rest
  } = props
  const [isOverflowing, setIsOverflowing] = useState(false)
  const textRef = useRef(null)

  useEffect(() => {
    const element = textRef.current
    if (element) {
      setIsOverflowing(element.scrollWidth > element.offsetWidth)
    }
  }, [text])

  return (
    <Tooltip title={isOverflowing ? tooltipText || text : ''} {...tooltipProps}>
      <Box
        ref={textRef}
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '100%'
        }}
        {...rest}
      >
        {text}
      </Box>
    </Tooltip>
  )
}

export default EllipsisTooltip
