import { makeStyles } from '@mui/styles'

import { useColor } from '../../../ThemeContext'

const useStyles = makeStyles((theme) => {
  const { selectedColor } = useColor() // Get the selected color from the context

  return {
    chatRootWrapper: {
      height: 'calc(100vh - 148px)',
      display: 'flex',
      flexFlow: 'column'
    },
    gradientTextStyle: {
      background: 'linear-gradient(100deg, #471CA8, #FF3465)',
      WebkitBackgroundClip: 'text',
      backgroundClip: 'text',
      color: 'transparent !important',
      display: 'inline-block',
      '&:before': {
        content: 'attr(data-text)',
        position: 'absolute',
        left: '0',
        top: '0',
        zIndex: '-1'
      }
    },
    chatContainer: {
      width: 'calc(100% - 20px)',
      height: 'calc(100% - 20px)',
      margin: 'auto',
      background: '#fff',
      padding: '10px'
      /* maxWidth: '800px', */
    },
    avatar: {
      color: 'var(--grey-100)',
      // border: `1.5px ${theme.palette.primary.main} solid`,
      backgroundColor: 'var(--grey-700)',
      fontSize: 12,
      // fontWeight: 600,
      fontFamily: 'PoppinsMedium',
      height: '25px',
      width: '25px',
      '& svg': {
        fontSize: '18px'
      }
    },
    pullWrapper: {
      color: '#6b6b6b6b',
      textAlign: 'center'
    },
    messageMe: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '0px 10px'
    },
    messageThem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '0px 10px'
    },
    messageText: {
      padding: '0px 10px',
      minWidth: '50px',
      color: 'var(--grey-700)',
      maxWidth: '650px',
      fontSize: '15px',
      wordBreak: 'break-word',
      '& p:first-of-type, & h1:first-of-type': {
        marginTop: 0,
        marginBottom: '0'
      }
    },
    filterContainer: {
      width: '100%',
      background: 'white',
      marginTop: '5px',
      maxWidth: '58rem',
      '& .MuiSvgIcon-root': {
        color: '#797979',
        fontSize: '18px'
      },
      display: 'flex',
      flexWrap: 'wrap'
    },

    messageList: {
      height: '1px',
      padding: '0px',
      marginTop: '10px',
      width: 'calc(100% - 4px)'
    },
    errorContainer: {
      color: 'red'
    },
    messageWrapper: {
      display: 'flex',
      maxWidth: '58rem',
      margin: 'auto',
      width: '100%',
      borderRadius: '10px',
      padding: '15px 0px',
      justifyContent: 'space-between',
      '& .MuiSvgIcon-root': {
        fontSize: '18px'
      }
    },
    avatarWrapper: {
      margin: '0px 15px',
      width: 'calc(100% - 31px)',
      display: 'flex'
    },
    avatarContainer: {
      maxWidth: '29px'
    },
    dotsWrapper: {
      height: '100%',
      display: 'flex',
      alignItems: 'center'
    },
    funcWrappers: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      minWidth: '25px'
    },
    sessionChatWrapper: {
      padding: '0 15%'
      // height: "84vh"
    },
    titleContent: {
      width: '25%',
      marginBottom: '20px',
      textAlign: 'center',
      fontFamily: 'PoppinsRegular',
      fontSize: '15px',
      fontWeight: 'bold',
      lineHeight: '24px'
    },
    contentText: {
      background: '#DBEAFF',
      padding: '10px',
      width: '25%',
      marginBottom: '30px',
      textAlign: 'center',
      fontFamily: 'PoppinsRegular',
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '19.5px',
      borderRadius: '4px',
      height: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    hoverAction: {
      cursor: 'pointer'
    },
    feedbackWrappers: {
      display: 'flex'
    },
    iconActive: {
      color: theme.palette.primary.main,
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        width: '25px',
        height: '15px'
      }
    },
    iconInActive: {
      color: theme.palette.primary.secondary,
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        width: '25px',
        height: '15px'
      }
    },
    disclaimer: {
      fontFamily: 'PoppinsRegular',
      fontSize: '10px',
      textAlign: 'left',
      fontWeight: '400',
      margin: '5px 0px',
      marginLeft: '3px',
      color: 'var(--grey-500)'
    },
    additionalDataWrapper: {
      fontStyle: 'italic',
      fontSize: '12px',
      color: 'grey',
      cursor: 'pointer'
    },
    imageContainer: {
      padding: '10px',
      minWidth: '50px',
      color: '#696969',
      maxWidth: '650px',
      fontSize: '15px',
      wordBreak: 'break-word',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: '100%',
      height: 'auto',
      flexWrap: 'wrap',
      gap: '10px',
      paddingTop: '0px',
      '& span': {
        display: 'flex !important'
      }
    },
    imageWrapper: {
      justifyContent: 'center',
      display: 'flex',
      alignItems: 'center',
      objectFit: 'contain',
      cursor: 'pointer',
      width: '250px',
      minHeight: '250px',
      background: '#e6f1ff',
      position: 'relative',
      '& .overlayButtons': {
        top: 'inherit',
        right: 'inherit',
        backgroundColor: 'transparent'
      },
      '&:hover': {
        '& img': {
          opacity: '0.5 !important'
        },
        '& .overlayButtons': {
          opacity: '1 !important'
        }
      }
    },
    tagsWrapper: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      flex: 1,
      width: '100%',
      overflow: 'auto'
    },
    tags: {
      '& button': {
        minWidth: '100px',
        maxWidth: '200px'
      },
      '& #multiCheckboxWrapper': {
        width: 'auto !important',
        '& button': {
          fontSize: '12px',
          padding: '1px 4px'
        }
      },
      '& #multiCheckboxOption': {
        width: '100% !important',
        fontWeight: '500',
        fontSize: '12px',
        color: '#000000',
        '& .MuiSvgIcon-root': {
          fontSize: '20px'
        },
        '& .MuiCheckbox-root': {
          padding: '5px'
        }
      },
      '& .Mui-checked': {
        color: selectedColor + ' !important'
      },
      '& #multiCheckboxMenuWrapper': {
        border: '1px solid #E5E5E5 !important',
        borderRadius: '4px !important',
        marginTop: '5px'
      },
      '& #multiCheckboxMenu': {
        width: '250px !important',
        '& input': {
          fontSize: '12px !important'
        }
      }
    },
    tagsSelected: {
      '& button': {
        color: selectedColor,
        border: '1px solid ' + selectedColor
      }
    },
    chatFilters: {
      marginTop: '10px',
      display: 'flex',
      padding: '0 10px',
      gap: '10px',
      flexWrap: 'wrap',
      overflow: 'hidden',
      maxHeight: '25px'
    },
    chatFiltersExpanded: {
      maxHeight: 'none',
      overflow: 'visible'
    },
    chatFiltersText: {
      fontSize: '12px',
      border: '1px solid #7979794f',
      borderRadius: '5px',
      color: '#808080',
      padding: '2px 4px'
    },
    chatTimeStamp: {
      fontSize: '12px',
      color: '#797979',
      fontStyle: 'italic',
      padding: '0 10px'
    },
    chatStatus: {
      fontSize: '12px',
      color: '#797979',
      fontStyle: 'italic',
      padding: '0px',
      display: 'flex',
      gap: '5px'
    },
    emptyWrapper: {
      marginTop: '20px',
      padding: '20px',
      maxWidth: '58rem',
      margin: 'auto',
      width: 'calc(100% - 40px)'
    },
    emptyWrapperMin: {
      marginTop: '6px',
      maxWidth: '58rem',
      margin: 'auto',
      padding: '10px',
      width: 'calc(100% - 20px)',
      boxSizing: 'border-box'
    },
    emptyTitleMin: {
      fontSize: '20px',
      color: '#797979',
      // marginTop: '20px',
      fontWeight: 'bold'
    },
    emptyTitle: {
      fontSize: '28px',
      color: '#797979',
      marginTop: '20px',
      fontWeight: 'bold'
    },
    emptyContent: {
      fontSize: '16px',
      color: '#797979',
      marginTop: '10px'
    },
    emptyContentMin: {
      fontSize: '13px',
      color: '#797979',
      fontWeight: '500',
      marginTop: '10px'
    },
    promptWrapper: {
      display: 'flex',
      gap: '10px',
      flexWrap: 'wrap',
      marginTop: '20px'
    },
    promptWrapperMin: {
      display: 'flex',
      flexDirection: 'column',
      gap: '3px',
      flexWrap: 'wrap',
      marginTop: '20px'
    },
    promptbtn: {
      color: '#000',
      borderRadius: '5px',
      padding: '6px 10px',
      marginTop: '5px',
      cursor: 'pointer',
      position: 'relative',
      '&:before': {
        content: '""',
        position: 'absolute',
        inset: '0',
        borderRadius: '4px',
        padding: '1px',
        background: 'linear-gradient(100deg, #471CA8, #FF3465)',
        WebkitMask:
          'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
        WebkitMaskComposite: 'xor',
        maskComposite: 'exclude'
      }
    },
    promptbtnMin: {
      fontSize: '13px',
      fontWeight: '500',
      color: '#000',
      borderRadius: '50px',
      padding: '5px 10px',
      marginTop: '5px',
      cursor: 'pointer',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
      '&:before': {
        content: '""',
        position: 'absolute',
        inset: '0',
        borderRadius: '50px',
        padding: '1px',
        background: 'linear-gradient(100deg, #471CA8, #FF3465)',
        WebkitMask:
          'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
        WebkitMaskComposite: 'xor',
        maskComposite: 'exclude'
      }
    },
    customButton: {
      padding: '2px 6px',
      fontWeight: '400 !important',
      border: '1px solid transparent !important',
      fontSize: '13px',
      '&:before': {
        content: '""',
        position: 'absolute',
        inset: '0',
        borderRadius: '4px',
        padding: '1px',
        background: 'linear-gradient(100deg, #471CA8, #FF3465)',
        WebkitMask:
          'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
        WebkitMaskComposite: 'xor',
        maskComposite: 'exclude'
      }
    },
    dialogWrapper: {
      width: 'calc(100% - 20px)',
      backgroundColor: 'white',
      padding: '10px',
      overflow: 'auto',
      maxWidth: '1200px',
      margin: 'auto',
      height: 'calc(100% - 20px)'
    },
    dialogTitleWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '20px'
    },
    dialogButtonWrapper: {
      display: 'flex',
      gap: '5px',
      alignItems: 'center'
    },
    titleText: {
      fontSize: '30px',
      fontWeight: '600',
      color: '#797979'
    },
    promptLibraryWrapper: {
      padding: '10px',
      display: 'flex',
      height: 'calc(100% - 20px)'
    },
    hideMenuListText: {
      fontSize: '12px',
      fontWeight: '500',
      color: '#000000',
      width: '100px',
      whiteSpace: 'normal',
      wordWrap: 'break-word',
      overflowWrap: 'break-word'
    },
    hideMenu: {
      '& .MuiSvgIcon-root': {
        fontSize: '18px'
      },
      '& .MuiButtonBase-root': {
        padding: '3px 5px'
      },
      '& .MuiMenu-paper': {
        border: '1px solid #E5E5E5',
        marginTop: '10px'
      },
      '& .MuiList-root': {
        padding: '8px'
      }
    },
    iconButton: {
      padding: '0px !important'
    },
    icon: {
      fontSize: '20px !important',
      color: '#797979'
    },
    promptTitleWrapper: {
      display: 'flex',
      gap: '10px',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0px 20px',
      marginBottom: '5px'
    },
    promptHeading: {
      fontSize: '18px',
      fontWeight: '600',
      color: '#000'
    },
    promptSearchWrapper: {
      display: 'flex',
      gap: '10px',
      justifyContent: 'flex-end',
      alignItems: 'center'
    },
    promptTags: {
      width: '240px',
      height: '100%',
      borderRight: '1px solid #E5E5E5',
      overflow: 'auto',
      padding: '0px 10px',
      '& .MuiButtonBase-root': {
        padding: '3px 5px'
      },
      '& .MuiListItem-root': {
        padding: '0px'
      },
      '& .MuiListItemButton-root': {
        borderLeft: '2px solid transparent'
      },
      '& .Mui-selected': {
        borderLeft: '2px solid ' + selectedColor
      }
    },
    promptContent: {
      flex: 1,
      height: '100%',
      width: '100%'
    },
    promptTag: {
      color: '#000',
      wordBreak: 'break-word',
      fontSize: '15px'
    },
    promptTagValue: {
      color: '#797979',
      wordBreak: 'break-word',
      fontSize: '14px'
    },
    promptTitle: {
      fontSize: '16px',
      color: '#000'
    },
    promptDescription: {
      fontSize: '16px',
      color: '#797979'
    },
    prompt: {
      marginTop: '5px',
      marginBottom: '5px',
      fontSize: '14px',
      color: '#797979'
    },
    promptUpdated: {
      fontSize: '14px',
      color: '#797979',
      fontStyle: 'italic'
    },
    promptContainer: {
      padding: '5px 20px'
    },
    promptContainerDisabled: {
      opacity: '0.5'
    },
    promptWrapperOver: {
      display: 'flex',
      padding: '5px',
      marginBottom: '5px',
      width: 'calc(100% - 10px)',
      borderLeft: '2px solid transparent',
      '&:hover': {
        borderLeft: '2px solid' + selectedColor
      }
    },
    promptTagList: {
      fontSize: '12px',
      color: '#797979',
      display: 'flex',
      gap: '5px'
    },
    tagWrapper: {
      display: 'flex',
      gap: '5px',
      border: '1px solid #E5E5E5',
      borderRadius: '5px',
      padding: '3px 5px',
      marginBottom: '5px'
    },
    messageBg: {
      border: '1px solid #f8f8ff',
      background: 'var(--grey-100)',
      width: 'calc(100% - 2px)'
    },
    filterButton: {
      fontSize: '13px',
      color: '#797979',
      padding: '2px 4px',
      border: '1px solid #797979',
      borderRadius: '5px',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)'
      }
    },
    showPromptWrapper: {
      padding: '0px 10px',
      width: '100%'
    },
    showPrompt: {
      fontStyle: 'italic',
      fontSize: '12px',
      color: 'grey',
      cursor: 'pointer',
      marginRight: '10px'
    },
    usePrompt: {
      fontSize: '12px',
      color: '#797979',
      padding: '2px 4px',
      border: '1px solid rgba(0, 0, 0, 0.87)',
      borderRadius: '5px'
    },
    noPrompt: {
      fontSize: '16px',
      color: '#797979',
      fontStyle: 'italic'
    },
    promptButton: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)'
      }
    },
    inputContainer: {
      background: '#fff',
      borderRadius: '5px',
      padding: '10px',
      marginTop: '5px',
      maxWidth: '58rem',
      margin: 'auto',
      textAlign: 'center'
    },
    inputContainerSession: {
      background: '#fff',
      padding: '0 15px 20px',
      marginTop: '5px',
      maxWidth: '58rem',
      margin: 'auto',
      textAlign: 'center'
    },
    inputContainerSessionMin: {
      background: '#fff',
      padding: '0 11px 7px',
      marginTop: '5px',
      maxWidth: '58rem',
      margin: 'auto',
      textAlign: 'center'
    },
    contentEditable: {
      lineHeight: '26px',
      background: 'var(--grey-50)',
      padding: '0px',
      textAlign: 'start',
      flex: 1,
      fontSize: '14px',
      // minHeight: '30px',
      wordBreak: 'break-word',
      '&:focus': {
        outline: 'none'
      }
    },
    contentWrapper: {
      border: '1px solid var(--grey-200)',
      borderBottom: 'none',
      width: 'calc(100% - 18px)',
      borderRadius: '8px 8px 0px 0px',
      padding: '6px 8px',
      display: 'flex',
      flexDirection: 'column',
      background: 'var(--grey-50)',
      overflow: 'auto'
    },
    contentEditableDisabled: {
      background: '#f5f5f5'
    },
    contentEditableWrapper: {
      flexDirection: 'column',
      display: 'flex',
      fontSize: '13px',
      background: 'var(--grey-50)'
    },
    endAdornment: {
      marginTop: 'auto',
      '& .MuiSvgIcon-root': {
        fontSize: '20px'
      }
    },
    endAdornmentWithCloseButton: {
      display: 'flex',
      flexDirection: 'column',
      gap: '45px'
    },
    imageToolbar: {
      border: '1px solid #CBCBCB',
      color: 'rgb(48, 55, 64)',
      borderRadius: '0 0 8px 8px',
      borderWidth: '0 1px 1px 1px',
      padding: '8px',
      margin: '0 6px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontSize: '14px'
    },
    resumeWrapper: {
      marginLeft: '10px',
      '& button': {
        borderColor: 'rgba(0, 0, 0, 0.12)',
        '&:hover': {
          borderColor: 'rgba(0, 0, 0, 0.12) !important'
        }
      },
      '& svg': {
        color: 'rgb(48, 55, 64)'
      }
    },
    favIconWrapper: {
      padding: '0px 8px',
      '& .MuiSvgIcon-root': {
        fontSize: '19px',
        color: 'rgba(0, 0, 0, 0.87)'
      }
    }
  }
})

export { useStyles }
